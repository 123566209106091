import { useQuery } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"

interface Options {
  workspace_id: string
  connected_account_id: string
}

export const useConnectedAccount = ({
  connected_account_id,
  workspace_id,
}: Options) => {
  return useQuery({
    queryKey: ["connected_accounts", workspace_id, connected_account_id],
    queryFn: async () => {
      const client = getSeamHttpClient(workspace_id)
      const result = await client.connectedAccounts.get({
        connected_account_id,
      })
      return result
    },
    enabled: Boolean(connected_account_id),
  })
}
