import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "."

export const useDevice = (opts: {
  workspace_id?: string | null
  device_id?: string | null
}) => {
  const { workspace_id, device_id } = opts
  const seam = useSeamHttpClient(workspace_id!)
  return useQuery({
    queryKey: ["devices", workspace_id, device_id],
    queryFn: () => seam.devices.get({ device_id: device_id ?? "" }),
    enabled: Boolean(seam && workspace_id && device_id),
  })
}
