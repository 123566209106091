import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "api-hooks/index"

export const useDeleteAccessCode = () => {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: (params: {
      workspace_id: string
      access_code_id: string
      device_id: string
    }) => {
      const client = getSeamHttpClient(params.workspace_id)
      return client.accessCodes.delete({
        access_code_id: params.access_code_id,
      })
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: ["access_codes", params.workspace_id, params.device_id],
      })
    },
  })
}
