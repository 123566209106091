import { useQuery } from "@tanstack/react-query"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"
import { useSeamHttpClient } from "api-hooks/use-seam-client"

interface Params {
  workspace_id: string
  credential_manager_acs_system_id?: string
}

interface UseUserIdentitiesOptions {
  should_poll?: boolean
}

export const useUserIdentities = (
  { workspace_id, credential_manager_acs_system_id }: Params,
  options?: UseUserIdentitiesOptions
) => {
  const seam = useSeamHttpClient(workspace_id)
  return useQuery({
    queryKey: ["user_identities", workspace_id],
    queryFn: () =>
      seam?.userIdentities.list({
        ...(credential_manager_acs_system_id
          ? { credential_manager_acs_system_id }
          : {}),
      }),
    enabled: seam !== null,
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })
}
