import { getSeamHttpClient } from "./use-seam-client"

export const getWebhook = (args: {
  webhook_id: string
  workspace_id: string
}) => {
  const { webhook_id, workspace_id } = args
  const client = getSeamHttpClient(workspace_id)
  return client.webhooks.get({ webhook_id })
}
