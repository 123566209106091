import { type ConnectWebviewsCreateBody } from "@seamapi/http/connect"
import { useSeamHttpClient } from "./index"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const useCreateConnectWebview = ({
  workspace_id,
}: {
  workspace_id: string
}) => {
  const qc = useQueryClient()
  const seam = useSeamHttpClient(workspace_id)

  return useMutation({
    mutationFn: async (opts: ConnectWebviewsCreateBody) => {
      return seam?.connectWebviews.create(opts)
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["connect_webviews", workspace_id] })
    },
  })
}
