import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"

interface UseDevicesParams {
  workspace_id: string
  connected_account_id?: string
}

export const useDevicesWithAccount = ({
  workspace_id,
  connected_account_id,
}: UseDevicesParams) => {
  const seam = useSeamHttpClient(workspace_id)
  return useQuery({
    queryKey: ["devices", workspace_id, connected_account_id],
    queryFn: async () => {
      const managed_devices = await seam.devices.list({ connected_account_id })
      const unmanaged_devices = await seam.devices.unmanaged.list({
        connected_account_id,
      })
      return [...managed_devices, ...unmanaged_devices]
    },
    enabled: Boolean(seam && workspace_id && connected_account_id),
  })
}
