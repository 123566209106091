import { getAuthenticatedAxios } from "./use-authenticated-axios"

export const getWebhookDashboard = async (workspace_id: string) => {
  const axios = getAuthenticatedAxios(workspace_id)

  const {
    data: { svix_dashboard_access },
  } = await axios.post("/internal/webhooks/get_svix_dashboard_link")
  return svix_dashboard_access
}
