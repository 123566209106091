import { useQuery } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"

export const useAccessTokens = () => {
  const axios = getAuthenticatedAxios()

  return useQuery({
    queryKey: ["access_tokens"],
    queryFn: async () => {
      const {
        data: { access_tokens },
      } = await axios.get("/internal/access_tokens/list")
      return access_tokens
    },
  })
}
