import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "api-hooks/index"

export type LockLockDoorParams = {
  workspace_id: string
  device_id: string
}

export const useLockLock = () => {
  const qc = useQueryClient()
  return useMutation({
    mutationFn: async (params: LockLockDoorParams) => {
      const client = getSeamHttpClient(params.workspace_id)
      return client.locks.lockDoor({
        device_id: params.device_id,
      })
    },
    onSuccess: (_res, params: LockLockDoorParams) => {
      qc.invalidateQueries({
        queryKey: ["devices", params.workspace_id, params.device_id],
      })
    },
  })
}
