import { SeamHttp, errorInterceptor } from "@seamapi/http/connect"
import { useSessionState } from "core/hooks/use-session-state"
import {
  attachToastInterceptorsToClient,
  attachToastResponseInterceptorsToClient,
} from "./toast"
import { uuid4 } from "@sentry/utils"
import {
  DevToolApiInternalAxiosRequestConfig,
  useSeamDevTools,
} from "core/hooks/use-seam-dev-tools"

export function getSeamHttpClient<TWorkspaceId extends string | undefined>(
  workspace_id: TWorkspaceId
): TWorkspaceId extends string ? SeamHttp : null {
  if (workspace_id == null) return null as never
  if (globalThis.window == null) return null as never
  const session_token = useSessionState.getState().sessionToken
  if (session_token == null) return null as never

  const endpoint = process.env.NEXT_PUBLIC_API_HOST
  if (endpoint == null) return null as never

  const seam = SeamHttp.fromConsoleSessionToken(session_token, workspace_id, {
    endpoint,
    axiosOptions: {},
  })

  attachToastInterceptorsToClient(seam.client)

  return seam as never
}

export function useSeamHttpClient<TWorkspaceId extends string | undefined>(
  workspace_id: TWorkspaceId
) {
  const seam = getSeamHttpClient<TWorkspaceId>(workspace_id)

  useAttachSeamDevTool(seam?.client)
  return seam
}

export const useAttachSeamDevTool = (client?: SeamHttp["client"]): void => {
  const seamDevTools = useSeamDevTools()
  client?.interceptors.request.use((config) => {
    // eslint-disable-next-line no-extra-semi
    ;(config as DevToolApiInternalAxiosRequestConfig)._request_id = uuid4()
    seamDevTools.add(
      {
        create_at: new Date(),
        variant: "api-request",
        endpoint: config.url!,
        method: config.method!,
        params: config.data,
        id: uuid4(),
      },
      (config as DevToolApiInternalAxiosRequestConfig)._request_id
    )

    return config
  })

  if (client) {
    /**
     * we need to remove any interceptors that are already attached to the client because we need the pure error from axios
     */
    client.interceptors.response.clear()
    client.interceptors.response.use(
      (response) => {
        seamDevTools.add(
          {
            create_at: new Date(),
            variant: "api-response",
            endpoint: response.config.url!,
            response: response.data,
            id: uuid4(),
          },
          (response.config as DevToolApiInternalAxiosRequestConfig)._request_id
        )

        return response
      },
      (err) => {
        seamDevTools.add(
          {
            create_at: new Date(),
            variant: "api-response-error",
            endpoint: err.config.url!,
            response: err.response?.data,
            id: uuid4(),
          },
          err.config._request_id
        )
        throw err
      }
    )
    /**
     * now we can attach the seam error interceptor
     */
    client.interceptors.response.use(undefined, errorInterceptor)
    attachToastResponseInterceptorsToClient(client)
  }
}
