import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useDeleteApiKey = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async ({
      workspace_id,
      ...params
    }: Routes["/internal/api_keys/delete"]["jsonBody"] & {
      workspace_id: string
    }) => {
      const axios = getAuthenticatedAxios(workspace_id)
      const { data } = await axios.post("/internal/api_keys/delete", params)
      return data
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({ queryKey: ["api_keys", params.workspace_id] })
    },
  })
}
