import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useRemoveWorkspaceMember = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async ({
      workspace_id,
      ...params
    }: Routes["/internal/workspaces/remove_user"]["jsonBody"] & {
      workspace_id: string
    }) => {
      const axios = getAuthenticatedAxios(workspace_id)
      const { data } = await axios.post(
        "/internal/workspaces/remove_user",
        params
      )
      return data
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: ["workspace_members", params.workspace_id],
      })
    },
  })
}
