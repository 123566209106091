import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useUpdatePreferredWorkspace = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: Routes["/internal/users/update_preferred_workspace"]["jsonBody"]
    ) => {
      const axios = getAuthenticatedAxios(params.workspace_id)
      const { data } = await axios.post(
        "/internal/users/update_preferred_workspace",
        params
      )
      return data
    },
    onSuccess: () => {
      qc.invalidateQueries()
    },
  })
}
