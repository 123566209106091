import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useCreateAccessToken = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: Routes["/internal/access_tokens/create"]["jsonBody"]
    ) => {
      const axios = getAuthenticatedAxios()
      const {
        data: { access_token },
      } = await axios.post("/internal/access_tokens/create", params)
      return access_token
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["access_tokens"] })
    },
  })
}
