import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "api-hooks/use-seam-client"

interface Params {
  workspace_id?: string
}

export const useWorkspaceSettings = ({ workspace_id }: Params) => {
  const seam = useSeamHttpClient(workspace_id)
  return useQuery({
    queryKey: ["workspace_settings", workspace_id],
    queryFn: () =>
      seam?.client
        .get("/internal/workspaces/get_workspace_settings")
        .then((r) => r.data.workspace_settings),
    enabled: seam != null,
  })
}
