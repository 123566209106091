import { useRouter } from "core/hooks/use-router"
import { useEffect, useState } from "react"
import { SeamLogo } from "core/components/SeamLogo"
import MuiBox from "@mui/material/Box"
import { Container } from "@mui/material"
import styled from "@emotion/styled"
import { useSignOut } from "api-hooks/use-sign-out"

const Box = styled(MuiBox)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20%;
  width: 100%;
  height: 100%;
`

export const useLocalStorageRedirectCounter = () => {
  const [counterAr, setCounterAr] = useState(() => {
    if (typeof window === "undefined") return []
    let counterAr
    try {
      counterAr = JSON.parse(
        window.localStorage.getItem("local_storage_redirect_counter") || "[]"
      )
      if (!Array.isArray(counterAr)) throw new Error("counter is not array")
    } catch (e) {
      counterAr = []
    }
    window.localStorage.setItem(
      "local_storage_redirect_counter",
      JSON.stringify(counterAr)
    )
    return counterAr
  })

  return [
    counterAr.length,
    () => {
      const newCounterAr = counterAr
        .concat([Date.now()])
        .filter((n) => n > Date.now() - 3000)
      setCounterAr(newCounterAr)
      window.localStorage.setItem(
        "local_storage_redirect_counter",
        JSON.stringify(newCounterAr)
      )
      return newCounterAr.length
    },
  ] as const
}

export const SlowRedirect = ({ to }: { to: string }) => {
  const router = useRouter()
  const signOut = useSignOut()
  const [redirectCount, incRedirectCount] = useLocalStorageRedirectCounter()
  const tooManyRedirects = redirectCount > 6
  useEffect(() => {
    if (!to) return
    if (tooManyRedirects) return
    const timeout = setTimeout(() => {
      incRedirectCount()
      router.push(to)
    }, 500)
    return () => clearTimeout(timeout)
  }, [router, to, tooManyRedirects])

  return (
    <Container>
      <Box display="flex" flexDirection="column">
        <SeamLogo color="black" height={3} />
        {tooManyRedirects && (
          <div style={{ marginTop: 20 }}>
            Too many redirects ({redirectCount})
            <br />
            <button
              onClick={async () => {
                await signOut()
                router.push("/signin")
              }}
            >
              sign out
            </button>
          </div>
        )}
      </Box>
    </Container>
  )
}
