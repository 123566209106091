import { useQuery } from "@tanstack/react-query"
import { useAuthenticatedAxios } from "./use-authenticated-axios"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"

interface UseApiKeysOptions {
  should_poll?: boolean
}

export const useApiKeys = (workspace_id = "", options?: UseApiKeysOptions) => {
  const axios = useAuthenticatedAxios(workspace_id)

  return useQuery({
    queryKey: ["api_keys", workspace_id],
    queryFn: async () => {
      const {
        data: { api_key },
      } = await axios.get("/internal/api_keys/list")
      return api_key
    },
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })
}
