import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"

export const useConnectWebview = (opts: {
  workspace_id?: string
  connect_webview_id?: string
}) => {
  const seam = useSeamHttpClient(opts.workspace_id!)
  return useQuery({
    queryKey: ["connect_webview", opts.workspace_id, opts.connect_webview_id],
    queryFn: () =>
      seam.connectWebviews.get({
        connect_webview_id: opts.connect_webview_id as string,
      }),
    enabled: Boolean(seam && opts.workspace_id && opts.connect_webview_id),
  })
}
