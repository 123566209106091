import { useMutation, useQueryClient } from "@tanstack/react-query"
import toast from "react-hot-toast"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useRemoveWorkspaceMemberInvitationMutator = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({
      workspace_id,
      ...params
    }: Routes["/internal/workspace_invitations/delete"]["jsonBody"] & {
      workspace_id: string
    }) => {
      const axios = getAuthenticatedAxios(workspace_id)
      return toast.promise(
        axios
          .delete("/internal/workspace_invitations/delete", { data: params })
          .then((r) => r.data),
        {
          loading: `Removing invitation to ${params.email}`,
          success: `Removed invitation to ${params.email}`,
          error: `Failed to remove invitation to ${params.email}`,
        }
      )
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: ["invited_workspace_members", params.workspace_id],
      })
    },
  })
}
