import { useIsAuthenticated } from "core/hooks"
import { useQuery } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useBillingSummary = (
  params?: Routes["/internal/customer/get_billing_summary"]["queryParams"]
) => {
  const isAuthenticated = useIsAuthenticated()
  return useQuery({
    queryKey: ["billing_summary"],
    queryFn: async () => {
      const axios = getAuthenticatedAxios()
      try {
        const {
          data: { billing_summary },
        } = await axios.get(`/internal/customer/get_billing_summary`, {
          params,
        })
        return billing_summary
      } catch (error) {
        console.error("Error getting user billing summary: ", error)
        throw error
      }
    },
    enabled: isAuthenticated,
  })
}
