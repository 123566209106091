import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"
import { WebhooksUpdateBody } from "@seamapi/http/connect"

export const useUpdateWebhook = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({
      workspace_id,
      ...params
    }: WebhooksUpdateBody & { workspace_id: string }) => {
      const client = getSeamHttpClient(workspace_id)
      return client.webhooks.update(params)
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: [{ workspace_id: params.workspace_id }, "webhooks"],
      })
    },
  })
}
