import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"
import { WorkspacesResetSandboxBody } from "@seamapi/http/connect"

export const useResetSandbox = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({
      workspace_id,
      ...params
    }: WorkspacesResetSandboxBody & { workspace_id: string }) => {
      const client = getSeamHttpClient(workspace_id)
      return client.workspaces.resetSandbox(params)
    },
    onSuccess: () => {
      qc.invalidateQueries()
    },
  })
}
