import React from "react"
import { Link } from "core/components/Link"
import styled from "@emotion/styled"
import blackLogoSvg from "core/assets/SeamLogoBlack.svg"
import colorLogoSvg from "core/assets/SeamLogoColor.svg"
import { theme } from "core/theme"

const WIDTH_HEIGHT_RATIO = 3.5
const SMALL_RATIO = 0.75

type SeamLogoProps = {
  color: string
  height: number
  width: number
}

const StyledBlackLogo = styled("img")`
  ${(props: SeamLogoProps) => `height: ${props.height}em;`}
  ${(props: SeamLogoProps) => `width: ${props.width}em;`}
  vertical-align: middle;
  path.base {
    ${(props: SeamLogoProps) => `fill: ${props.color};`}
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    ${(props: SeamLogoProps) => `height: ${props.height * SMALL_RATIO}em;`}
    ${(props: SeamLogoProps) => `width: ${props.width * SMALL_RATIO}em;`}
  }
`

const StyledColorLogo = styled("img")`
  ${(props: SeamLogoProps) => `height: ${props.height}em;`}
  ${(props: SeamLogoProps) => `width: ${props.width}em;`}
  vertical-align: middle;
  path.base {
    ${(props: SeamLogoProps) => `fill: ${props.color};`}
  }
  @media (max-width: ${theme.breakpoints.values.sm}px) {
    ${(props: SeamLogoProps) => `height: ${props.height * SMALL_RATIO}em;`}
    ${(props: SeamLogoProps) => `width: ${props.width * SMALL_RATIO}em;`}
  }
`

interface LogoProps {
  className?: string | undefined
  color: string
  gradient?: boolean
  height?: number
}

export const SeamLogo = ({
  className = undefined,
  color,
  gradient,
  height,
}: LogoProps) => {
  let heightStyle = 2
  if (height) {
    heightStyle = height
  }
  const widthStyle = heightStyle * WIDTH_HEIGHT_RATIO
  let logo = (
    <StyledBlackLogo
      src={(blackLogoSvg as any).src}
      className={className}
      color={color}
      height={heightStyle}
      width={widthStyle}
    />
  )
  if (gradient) {
    logo = (
      <StyledColorLogo
        src={(colorLogoSvg as any).src}
        className={className}
        color={color}
        height={heightStyle}
        width={widthStyle}
      />
    )
  }
  return <Link to={"/"}>{logo}</Link>
}
