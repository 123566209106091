import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useUpdatePreferences = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: Routes["/internal/users/update_preferences"]["jsonBody"]
    ) => {
      const axios = getAuthenticatedAxios()
      const { data } = await axios.patch(
        "/internal/users/update_preferences",
        params
      )
      return data
    },
    onSuccess: () => {
      qc.invalidateQueries()
    },
  })
}
