import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useSeamHttpClient } from "api-hooks/index"

export type AccessCodeCreateOngoingParams = {
  device_id: string
  name: string
  code: string
  is_offline_access_code: boolean
  is_one_time_use: boolean
}

export type AccessCodeCreateScheduledParams = {
  device_id: string
  name: string
  code: string
  starts_at: string | Date
  ends_at: string | Date
  is_offline_access_code: boolean
  is_one_time_use: boolean
}

export type AccessCodeCreateParams =
  | AccessCodeCreateOngoingParams
  | AccessCodeCreateScheduledParams

export const useCreateAccessCode = (workspace_id: string) => {
  const qc = useQueryClient()
  const client = useSeamHttpClient(workspace_id)
  return useMutation({
    mutationFn: (params: AccessCodeCreateParams) => {
      const accessCodeParams = {
        device_id: params.device_id,
        name: params.name,
        code: params.is_offline_access_code ? undefined : params.code,
        is_offline_access_code: params.is_offline_access_code,
        is_one_time_use: params.is_one_time_use,
        starts_at: "starts_at" in params ? String(params.starts_at) : undefined,
        ends_at: "ends_at" in params ? String(params.ends_at) : undefined,
      }

      return client.accessCodes.create(accessCodeParams)
    },
    onSuccess: (response, params) => {
      qc.invalidateQueries({
        queryKey: ["access_codes", workspace_id, params.device_id],
      })
    },
  })
}
