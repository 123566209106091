import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"
import { WebhooksDeleteParams } from "@seamapi/http/connect"

export const useDeleteWebhook = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async ({
      workspace_id,
      ...params
    }: WebhooksDeleteParams & { workspace_id: string }) => {
      const client = getSeamHttpClient(workspace_id)
      return client.webhooks.delete(params)
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: [{ workspace_id: params.workspace_id }, "webhooks"],
      })
    },
  })
}
