import { useSaveIsAuthenticated } from "core/hooks/use-is-authenticated"
import { useMutation } from "@tanstack/react-query"
import defaultAxios, { AxiosError } from "axios"
import toast from "react-hot-toast"
import { useSignOut } from "./use-sign-out"

interface CreateSessionParams {
  clerkToken: string
}

const unauthenticatedAxios = defaultAxios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
})

export const useLogin = () => {
  const saveIsAuthenticated = useSaveIsAuthenticated()
  const signOut = useSignOut()
  return useMutation({
    mutationFn: async (params: CreateSessionParams) => {
      const res = await unauthenticatedAxios
        .post("/internal/sessions/create", { token: params.clerkToken })
        .catch((e: AxiosError<{ error: { message: string } }, any>) => ({
          error: e,
        }))
      if ("error" in res) {
        const seamHealthRes = await unauthenticatedAxios
          .get("/health")
          .catch(() => ({ data: { ok: false } }))

        if (!seamHealthRes.data.ok) {
          toast.error("Error creating session, Seam API is not responding")
        } else {
          console.log(
            "Failed to create session: ",
            res.error.toString(),
            res.error?.response?.data
          )
          toast.error(
            `Failed to create session! "${res.error?.response?.data?.error?.message}" Signing you out...`
          )
        }
        setTimeout(() => {
          signOut()
        }, 5000)

        throw res.error
      } else {
        saveIsAuthenticated(true, res?.data?.session_token)
      }
    },
  })
}
