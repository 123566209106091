import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useCreateWorkspace = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: Routes["/internal/workspaces/create"]["jsonBody"]
    ) => {
      const axios = getAuthenticatedAxios()
      const result = await axios.post("/internal/workspaces/create", params)
      return result.data
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["workspaces"] })
    },
  })
}
