import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"

interface UseAccessCodesOptions {
  should_poll?: boolean
}

export const useAccessCodes = (
  opts: {
    workspace_id: string
    device_id: string
  },
  options?: UseAccessCodesOptions
) => {
  const { workspace_id, device_id } = opts
  const seam = useSeamHttpClient(workspace_id)

  return useQuery({
    queryKey: ["access_codes", workspace_id, device_id],
    queryFn: () => seam.accessCodes.list({ device_id }),
    enabled: Boolean(seam && workspace_id && device_id),
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })
}
