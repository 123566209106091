import { useSaveIsAuthenticated } from "core/hooks/use-is-authenticated"
import { useMutation } from "@tanstack/react-query"
import defaultAxios from "axios"

interface LoginCredentials {
  email: string
}

const unauthenticatedAxios = defaultAxios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST,
})

export const usePasswordlessLogin = () => {
  const saveIsAuthenticated = useSaveIsAuthenticated()
  return useMutation({
    mutationFn: async (loginCreds: LoginCredentials) => {
      const res = await unauthenticatedAxios.post("/internal/sessions/create", {
        password: "dummypassword",
        ...loginCreds,
      })
      saveIsAuthenticated(true, res?.data?.session_token)
    },
  })
}
