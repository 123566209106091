import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"
import {
  ClientSessionsListParams,
  ClientSessionsListResponse,
} from "@seamapi/http/connect"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"

interface UseClientSessionsParams extends ClientSessionsListParams {
  workspace_id: string
}

interface UseClientSessionsOptions {
  should_poll?: boolean
}

export const useClientSessions = (
  params: UseClientSessionsParams,
  options?: UseClientSessionsOptions
) => {
  const { workspace_id, ...listParams } = params
  const client = useSeamHttpClient(workspace_id)
  return useQuery({
    queryKey: ["client_sessions", workspace_id, listParams],
    queryFn: async () => {
      const result = await client.clientSessions.list(listParams)
      // @todo the following intersection should not be necessary - if you're reading this try and remove it.
      return result as (ClientSessionsListResponse["client_sessions"][number] & {
        token: string
      })[]
    },
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })
}
