import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useCreateApiKey = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: Routes["/internal/api_keys/create"]["jsonBody"] & {
        workspace_id: string
      }
    ) => {
      const axios = getAuthenticatedAxios(params.workspace_id)
      const result = await axios.post("/internal/api_keys/create", {
        name: params.name,
      })
      return result.data.api_key
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({ queryKey: ["api_keys", params.workspace_id] })
    },
  })
}
