import { InternalAxiosRequestConfig } from "axios"
import { create } from "zustand"

export type DevToolApiInternalAxiosRequestConfig =
  InternalAxiosRequestConfig<any> & {
    _request_id: string
  }

export type DevToolApiRequest = {
  variant: "api-request"
  endpoint: string
  method: string
  params: any
  id: string
  create_at: Date
}

export type DevToolApiResponse = {
  id: string
  variant: "api-response"
  endpoint: string
  response: any
  create_at: Date
}

export type DevToolApiResponseError = {
  id: string
  variant: "api-response-error"
  endpoint: string
  response: any
  create_at: Date
}

export type DevToolApiRequests =
  | DevToolApiRequest
  | DevToolApiResponse
  | DevToolApiResponseError

interface SeamAPIGuide {
  requests: {
    [endpoint: string]: {
      [id: string]: DevToolApiRequests[]
    }
  }
  add: (guide: DevToolApiRequests, request_id: string) => void
  clear: () => void
  isDevToolsOpen: boolean
  openDevTools: () => void
  closeDevTools: () => void
}

export const useSeamDevTools = create<SeamAPIGuide>()((set) => ({
  requests: {},
  add: (request, request_id) =>
    set((state) => ({
      requests: {
        ...state.requests,
        [request.endpoint]: {
          ...state.requests[request.endpoint],
          [request_id]: [
            ...(state.requests[request.endpoint]?.[request_id] ?? []),
            request,
          ],
        },
      },
    })),
  clear: () => set({ requests: {} }),
  isDevToolsOpen: false,
  openDevTools: () => set({ isDevToolsOpen: true }),
  closeDevTools: () => set({ isDevToolsOpen: false }),
}))
