import { WebhooksCreateBody } from "@seamapi/http/connect"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"

export const useCreateWebhook = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async ({
      workspace_id,
      ...params
    }: WebhooksCreateBody & { workspace_id: string }) => {
      const client = getSeamHttpClient(workspace_id)
      return client.webhooks.create(params)
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: [{ workspace_id: params.workspace_id }, "webhooks"],
      })
    },
  })
}
