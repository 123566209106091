import { useEffect } from "react"
import { useInfiniteQuery } from "@tanstack/react-query"
import type { DevicesListParams } from "@seamapi/http/connect"
import { useSeamHttpClient } from "./use-seam-client"
import { Device, UnmanagedDevice } from "src/canonicalTypes"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"

interface UseDevicesOptions extends DevicesListParams {
  should_poll?: boolean
}

export function useDevices<TDevice extends Device | UnmanagedDevice = Device>(
  workspace_id: string,
  options?: UseDevicesOptions
) {
  const seam = useSeamHttpClient(workspace_id)

  // useInfiniteQuery is a hook provided by react-query that allows us to fetch data in a paginated way
  // We're using it here so that we don't have to block until all devices are loaded
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["devices", workspace_id, options ?? ""],
    queryFn: async ({ pageParam }) => {
      return await seam.devices.list({
        ...options,
        created_before: pageParam,
        limit: 2000,
      })
    },
    initialPageParam: new Date(),
    getNextPageParam: (lastPage) =>
      lastPage.length > 0
        ? new Date(lastPage[lastPage.length - 1]!.created_at)
        : undefined,
    enabled: Boolean(seam) && Boolean(workspace_id),
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })

  // Usually `fetchNextPage` is called when the user scrolls to the bottom (infinite scroll) or clicks a "Load More" button.
  // since we currently want to load all devices at once, we call `fetchNextPage` until we don't have more data to load
  useEffect(() => {
    if (hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetching, isFetchingNextPage, fetchNextPage])

  const allDevices = data?.pages.flat() ?? []

  return { data: allDevices as TDevice[], isLoading: status === "pending" }
}
