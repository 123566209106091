import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"
import { ConnectedAccountsDeleteParams } from "@seamapi/http/connect"

export const useDeleteConnectedAccount = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async ({
      workspace_id,
      ...params
    }: ConnectedAccountsDeleteParams & { workspace_id: string }) => {
      const client = getSeamHttpClient(workspace_id)
      const result = await client.connectedAccounts.delete(params)
      return result
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({
        queryKey: [
          "connected_accounts",
          params.workspace_id,
          params.connected_account_id,
        ],
      })
    },
  })
}
