import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"

interface UseConnectedAccountsOptions {
  should_poll?: boolean
}

export const useConnectedAccounts = (
  workspace_id = "",
  options?: UseConnectedAccountsOptions
) => {
  const seam = useSeamHttpClient(workspace_id)
  return useQuery({
    queryKey: ["connected_accounts", workspace_id],
    queryFn: () => seam.connectedAccounts.list(),
    enabled: Boolean(seam) && Boolean(workspace_id),
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })
}
