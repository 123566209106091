import toast from "react-hot-toast"
import { AxiosInstance, AxiosError } from "axios"

const toastableEndpoints = [
  "/locks/lock_door",
  "/locks/unlock_door",
  "/access_codes/create",
  "/access_codes/delete",
  "/access_codes/unmanaged/delete",
  "/access_codes/unmanaged/convert_to_managed",
]

export const attachToastInterceptorsToClient = (
  client: AxiosInstance
): void => {
  client.interceptors.request.use(
    (config) => {
      const url = config.url
      if (url != null && isToastableEndpoint(url)) {
        const toastId = toast.loading(url)
        return { ...config, toastId }
      }
      return config
    },
    (err) => {
      if (!isAxiosError(err)) throw err
      const url = err.config?.url
      const toastId = (err.config as any)?.toastId
      if (url != null && toastId != null) {
        toast.error(url, { id: toastId })
      }
      throw err
    }
  )

  attachToastResponseInterceptorsToClient(client)
}

export const attachToastResponseInterceptorsToClient = (
  client: AxiosInstance
) => {
  client.interceptors.response.use(
    (response) => {
      const url = response.config?.url
      const toastId = (response.config as any)?.toastId
      if (url != null && toastId != null) {
        toast.success(url, { id: toastId })
      }
      return response
    },
    (err) => {
      if (!isAxiosError(err)) throw err
      const url = err.config?.url
      const toastId = (err.config as any)?.toastId
      if (url != null && toastId != null) {
        toast.error(url, { id: toastId })
      }
      throw err
    }
  )
}

// TODO: Import this from axios when updated to axios v1
const isAxiosError = (err: any): err is AxiosError => "config" in err

const isToastableEndpoint = (endpoint: string) =>
  toastableEndpoints.includes(endpoint)
