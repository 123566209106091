import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getAuthenticatedAxios } from "./use-authenticated-axios"
import { Routes } from "src/all-routes"

export const useDeleteAccessToken = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (
      params: Routes["/internal/access_tokens/delete"]["commonParams"]
    ) => {
      const axios = getAuthenticatedAxios()
      const { data } = await axios.post(
        "/internal/access_tokens/delete",
        params
      )
      return data
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["access_tokens"] })
    },
  })
}
