import { Link as MuiLink } from "@mui/material"
import { useRouter } from "core/hooks"
import { ReactNode, forwardRef } from "react"

export const Link = forwardRef(function Link(
  { to, children }: { to: string; children?: ReactNode },
  ref
) {
  const router = useRouter()
  if (typeof children === "string") {
    return (
      <MuiLink ref={ref as any} onClick={() => router.push(to)}>
        {children}
      </MuiLink>
    )
  } else {
    return (
      <span ref={ref as any} onClick={() => router.push(to)}>
        {children}
      </span>
    )
  }
})
