import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"

interface Params {
  workspace_id: string
  device_id: string
  name: string
}

export const useUpdateDevice = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: (params: Params) => {
      const seam = useSeamHttpClient(params.workspace_id)
      return seam.devices.update({
        device_id: params.device_id,
        name: params.name,
      })
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({ queryKey: ["devices", params.workspace_id] })
    },
  })
}
