import { useQuery } from "@tanstack/react-query"
import { useSeamHttpClient } from "./use-seam-client"

export const useConnectWebviews = (workspace_id: string) => {
  const seam = useSeamHttpClient(workspace_id)
  return useQuery({
    queryKey: ["connect_webviews", workspace_id],
    queryFn: () => seam.connectWebviews.list(),
    enabled: Boolean(seam) && Boolean(workspace_id),
  })
}
