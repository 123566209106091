import { useMutation, useQueryClient } from "@tanstack/react-query"
import { getSeamHttpClient } from "./use-seam-client"
import { DevicesSimulateRemoveParams } from "@seamapi/http/connect"

export const useRemoveDevice = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async ({
      workspace_id,
      ...params
    }: DevicesSimulateRemoveParams & { workspace_id: string }) => {
      const client = getSeamHttpClient(workspace_id)
      const result = await client.devices.simulate.remove(params)
      return result
    },
    onSuccess: (_, params) => {
      qc.invalidateQueries({ queryKey: ["devices", params.workspace_id] })
    },
  })
}
