import { useQuery } from "@tanstack/react-query"
import { default_refetch_interval_ms } from "api-hooks/default-refetch-interval"
import { useSeamHttpClient } from "api-hooks/use-seam-client"

interface Params {
  workspace_id: string
  user_identity_id: string
}

interface UseEnrollmentAutomationsOptions {
  should_poll?: boolean
}

export const useEnrollmentAutomations = (
  { workspace_id, user_identity_id }: Params,
  options?: UseEnrollmentAutomationsOptions
) => {
  const seam = useSeamHttpClient(workspace_id)

  return useQuery({
    queryKey: ["enrollment_automations", user_identity_id, workspace_id],
    queryFn: () =>
      seam.userIdentities.enrollmentAutomations.list({
        user_identity_id,
      }),
    enabled: seam != null && user_identity_id != null,
    refetchInterval: options?.should_poll
      ? default_refetch_interval_ms
      : undefined,
  })
}
