import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useAuthenticatedAxios } from "./use-authenticated-axios"

export const useDeleteWorkspace = () => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: async (params: { workspace_id: string }) => {
      const axios = useAuthenticatedAxios(params.workspace_id)

      const r = await axios.delete("/internal/workspaces/delete", {
        data: { workspace_id: params.workspace_id },
      })
      return r.data
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["workspaces"] })
    },
  })
}
