import type { Routes } from "src/all-routes"
import { getAuthenticatedAxios } from "./use-authenticated-axios"

export const waitForActionAttempt = async (params: {
  workspace_id: string
  action_attempt_id: string
}) => {
  const axios = getAuthenticatedAxios(params.workspace_id)
  let action_attempt:
    | undefined
    | Routes["/action_attempts/get"]["jsonResponse"]["action_attempt"]
  while (!action_attempt || action_attempt.status === "pending") {
    const { data: res } = await axios.get(`/action_attempts/get`, {
      params: {
        action_attempt_id: params.action_attempt_id,
      },
    })
    action_attempt = res.action_attempt
    if (action_attempt.status !== "pending") break
    await new Promise((resolve) => setTimeout(resolve, 200))
  }
  return action_attempt
}
